var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, Suspense } from "react";
import Html from './html';
import { LazyLoadModules } from "./helpers/lazyLoadModules";
import { useLocation, useNavigate } from 'react-router-dom';
import { listenDeviceRemoved, listenDisposeCookies, pageClientQueryData, checkVppaCompliance, embedCode, handlePageViewEvent, handleUserLogout, initGTM, fireLogout, fetchUserDetails, listenToChannel, handleUserTransactions, handleQueryUrls, verifyMagicLink, getQueryParams } from "./helpers";
import "./styles/siteStyles.scss";
import { loadSiteStyles } from "./styles/helpers";
import { initializeConnection, syncSSEStore } from "./SSE";
import localforage from "localforage";
import pubsub from "@viewlift/pubsub";
// import { fetchUserDetails } from "./MFEs/Monetization-Sdk/src/fetchStore";
import request from "graphql-request";
import { abQuery } from "./Graphql/Queries/abQuery";
import './index.scss';
import NotFound404 from "./components/404";
import { fetchFeaturedTrayData } from "./helpers/manageSSE";
import Cookies from 'js-cookie';
import { listenAnalyticEvents, listenFBEvents } from './analyticListener.js';
import { BroadcastChannel } from 'broadcast-channel';
import './cookie.scss';
import CmsMenu from "./components/CmsMenu/index.js";
import SplashLoader from './modules/SplashScreens';
import PersonalizationBanner from './modules/PersonalizationBanner';
import { subscribeUserCreated, unsubscribeUserCreated } from "./OnBoarding-V2/Events/utils.events";
import logger from "./logger";
export var App = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41;
    var siteConfig = _a.siteConfig, pageData = _a.pageData, browserDetails = _a.browserDetails, xApiKey = _a.xApiKey, cachedAPI = _a.cachedAPI, apiBaseUrl = _a.apiBaseUrl, sseBaseUrl = _a.sseBaseUrl, analyticsBaseUrl = _a.analyticsBaseUrl, wssBaseUrl = _a.wssBaseUrl, env = _a.env, ip = _a.ip, embedCodeToken = _a.embedCodeToken, toolsUrl = _a.toolsUrl, templateUrl = _a.templateUrl;
    var _42 = __read(useState(pageData), 2), pageMetaData = _42[0], setPageData = _42[1];
    var _43 = __read(useState(null), 2), VLCookieConsent = _43[0], setVLCookieConsent = _43[1];
    var _44 = __read(useState(null), 2), oneTrust = _44[0], setOneTrust = _44[1];
    var appToken = !!Cookies.get('app-token');
    var navigate = useNavigate();
    var _45 = __read(useState(null), 2), activeSplash = _45[0], setActiveSplash = _45[1];
    var IntervalInstance;
    var configMessages = {};
    var disableVlCookieConsent = false;
    if (typeof window !== "undefined") {
        disableVlCookieConsent = ((_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.app_data) === null || _b === void 0 ? void 0 : _b.appcmsMain) === null || _c === void 0 ? void 0 : _c.privacy) === null || _d === void 0 ? void 0 : _d.disableVlCookieConsent) || false;
        configMessages = {
            cookieModalHeadingTitle: (_g = (_f = (_e = window === null || window === void 0 ? void 0 : window.app_data) === null || _e === void 0 ? void 0 : _e.appcmsMain) === null || _f === void 0 ? void 0 : _f.genericMessages) === null || _g === void 0 ? void 0 : _g.cookieModalHeadingTitle,
            cookieModalDescription: (_k = (_j = (_h = window === null || window === void 0 ? void 0 : window.app_data) === null || _h === void 0 ? void 0 : _h.appcmsMain) === null || _j === void 0 ? void 0 : _j.genericMessages) === null || _k === void 0 ? void 0 : _k.cookieModalDescription,
            requiredCookieAccordionTitle: (_o = (_m = (_l = window === null || window === void 0 ? void 0 : window.app_data) === null || _l === void 0 ? void 0 : _l.appcmsMain) === null || _m === void 0 ? void 0 : _m.genericMessages) === null || _o === void 0 ? void 0 : _o.requiredCookieAccordionTitle,
            requiredCookieAccordionDescription: (_r = (_q = (_p = window === null || window === void 0 ? void 0 : window.app_data) === null || _p === void 0 ? void 0 : _p.appcmsMain) === null || _q === void 0 ? void 0 : _q.genericMessages) === null || _r === void 0 ? void 0 : _r.requiredCookieAccordionDescription,
            optionalCookieAccordionTitle: (_u = (_t = (_s = window === null || window === void 0 ? void 0 : window.app_data) === null || _s === void 0 ? void 0 : _s.appcmsMain) === null || _t === void 0 ? void 0 : _t.genericMessages) === null || _u === void 0 ? void 0 : _u.optionalCookieAccordionTitle,
            optionalCookieAccordionDescription: (_x = (_w = (_v = window === null || window === void 0 ? void 0 : window.app_data) === null || _v === void 0 ? void 0 : _v.appcmsMain) === null || _w === void 0 ? void 0 : _w.genericMessages) === null || _x === void 0 ? void 0 : _x.optionalCookieAccordionDescription,
            functionalCookieTitle: (_0 = (_z = (_y = window === null || window === void 0 ? void 0 : window.app_data) === null || _y === void 0 ? void 0 : _y.appcmsMain) === null || _z === void 0 ? void 0 : _z.genericMessages) === null || _0 === void 0 ? void 0 : _0.functionalCookieTitle,
            functionalCookieDescription: (_3 = (_2 = (_1 = window === null || window === void 0 ? void 0 : window.app_data) === null || _1 === void 0 ? void 0 : _1.appcmsMain) === null || _2 === void 0 ? void 0 : _2.genericMessages) === null || _3 === void 0 ? void 0 : _3.functionalCookieDescription,
            performanceCookieTitle: (_6 = (_5 = (_4 = window === null || window === void 0 ? void 0 : window.app_data) === null || _4 === void 0 ? void 0 : _4.appcmsMain) === null || _5 === void 0 ? void 0 : _5.genericMessages) === null || _6 === void 0 ? void 0 : _6.performanceCookieTitle,
            performanceCookieDescription: (_9 = (_8 = (_7 = window === null || window === void 0 ? void 0 : window.app_data) === null || _7 === void 0 ? void 0 : _7.appcmsMain) === null || _8 === void 0 ? void 0 : _8.genericMessages) === null || _9 === void 0 ? void 0 : _9.performanceCookieDescription,
            cookieAcceptBtnLabel: (_12 = (_11 = (_10 = window === null || window === void 0 ? void 0 : window.app_data) === null || _10 === void 0 ? void 0 : _10.appcmsMain) === null || _11 === void 0 ? void 0 : _11.genericMessages) === null || _12 === void 0 ? void 0 : _12.cookieAcceptBtnLabel,
            cookieRejectBtnLabel: (_15 = (_14 = (_13 = window === null || window === void 0 ? void 0 : window.app_data) === null || _13 === void 0 ? void 0 : _13.appcmsMain) === null || _14 === void 0 ? void 0 : _14.genericMessages) === null || _15 === void 0 ? void 0 : _15.cookieRejectBtnLabel,
            cookieSaveSettingsBtnLabel: (_18 = (_17 = (_16 = window === null || window === void 0 ? void 0 : window.app_data) === null || _16 === void 0 ? void 0 : _16.appcmsMain) === null || _17 === void 0 ? void 0 : _17.genericMessages) === null || _18 === void 0 ? void 0 : _18.cookieSaveSettingsBtnLabel,
            cookieSettingsModalTitle: (_21 = (_20 = (_19 = window === null || window === void 0 ? void 0 : window.app_data) === null || _19 === void 0 ? void 0 : _19.appcmsMain) === null || _20 === void 0 ? void 0 : _20.genericMessages) === null || _21 === void 0 ? void 0 : _21.cookieSettingsModalTitle,
            cookieSettingsModalDescription: (_24 = (_23 = (_22 = window === null || window === void 0 ? void 0 : window.app_data) === null || _22 === void 0 ? void 0 : _22.appcmsMain) === null || _23 === void 0 ? void 0 : _23.genericMessages) === null || _24 === void 0 ? void 0 : _24.cookieSettingsModalDescription,
            cookieSettingsMoreInfoTitle: (_27 = (_26 = (_25 = window === null || window === void 0 ? void 0 : window.app_data) === null || _25 === void 0 ? void 0 : _25.appcmsMain) === null || _26 === void 0 ? void 0 : _26.genericMessages) === null || _27 === void 0 ? void 0 : _27.cookieSettingsMoreInfoTitle,
            cookieSettingsMoreInfoDescription: (_30 = (_29 = (_28 = window === null || window === void 0 ? void 0 : window.app_data) === null || _28 === void 0 ? void 0 : _28.appcmsMain) === null || _29 === void 0 ? void 0 : _29.genericMessages) === null || _30 === void 0 ? void 0 : _30.cookieSettingsMoreInfoDescription,
            cookieLetMeChooseButtonLabel: (_33 = (_32 = (_31 = window === null || window === void 0 ? void 0 : window.app_data) === null || _31 === void 0 ? void 0 : _31.appcmsMain) === null || _32 === void 0 ? void 0 : _32.genericMessages) === null || _33 === void 0 ? void 0 : _33.cookieLetMeChooseButtonLabel,
            privacyPolicyLinkLabel: (_36 = (_35 = (_34 = window === null || window === void 0 ? void 0 : window.app_data) === null || _34 === void 0 ? void 0 : _34.appcmsMain) === null || _35 === void 0 ? void 0 : _35.genericMessages) === null || _36 === void 0 ? void 0 : _36.privacyPolicyLinkLabel,
            contactUsLinkLabel: (_39 = (_38 = (_37 = window === null || window === void 0 ? void 0 : window.app_data) === null || _37 === void 0 ? void 0 : _37.appcmsMain) === null || _38 === void 0 ? void 0 : _38.genericMessages) === null || _39 === void 0 ? void 0 : _39.contactUsLinkLabel
        };
    }
    var location = useLocation();
    useEffect(function () {
        var importVLCookieConsent = function () { return __awaiter(void 0, void 0, void 0, function () {
            var module_1, VLCookieConsent_1, moduleWithDefault, VLCookieConsent_2, error_1, getCookie;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, import('@viewlift/cookie-consent/vlCookieConsent/esm/App')];
                    case 1:
                        module_1 = _a.sent();
                        if ('VLCookieConsent' in module_1) {
                            VLCookieConsent_1 = module_1.VLCookieConsent;
                            setVLCookieConsent(function () { return VLCookieConsent_1; });
                        }
                        else if ('default' in module_1) {
                            moduleWithDefault = module_1;
                            if (moduleWithDefault && moduleWithDefault.default) {
                                VLCookieConsent_2 = moduleWithDefault.default;
                                setVLCookieConsent(function () { return VLCookieConsent_2; });
                            }
                        }
                        else {
                            console.error('Invalid VLCookieConsent module structure:', module_1);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error importing VLCookieConsent:', error_1);
                        return [3 /*break*/, 3];
                    case 3:
                        listenAnalyticEvents();
                        if (Cookies.get("cc_cookie")) {
                            getCookie = JSON.parse(Cookies.get("cc_cookie"));
                            if (getCookie.level.includes("optional")) {
                                listenFBEvents();
                                initGTM();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        // Call the dynamic import function
        var isAppAccessing = getQueryParams(window.location.href) || null;
        if (typeof (window) !== undefined && !(window.location.href.indexOf("embed/player") > -1) && !isAppAccessing)
            importVLCookieConsent();
    }, []);
    useEffect(function () {
        var _a;
        if (typeof (window) !== undefined && ((_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain.features.embeddedCode)) {
            embedCode();
        }
        // if(window?.app_data?.appcmsMain?.privacy?.oneTrust?.enable && !(window.location.href.indexOf("embed/player") > -1)) {
        //   setOneTrust(true)
        //   insertCookieBanner()
        // }
    }, []);
    var checkForIsRedirected = function () { return __awaiter(void 0, void 0, void 0, function () {
        var redirected, comparePath;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, localforage.getItem("isRedirected")];
                case 1:
                    redirected = _b.sent();
                    comparePath = (_a = redirected === null || redirected === void 0 ? void 0 : redirected.path) === null || _a === void 0 ? void 0 : _a.split("?");
                    comparePath = (comparePath === null || comparePath === void 0 ? void 0 : comparePath.length) ? comparePath[0] : redirected === null || redirected === void 0 ? void 0 : redirected.path;
                    if (location.pathname === comparePath || (location === null || location === void 0 ? void 0 : location.pathname) === "/") {
                        localforage.setItem("isRedirected", { path: location === null || location === void 0 ? void 0 : location.pathname, redirect: false, isDetailPage: false, isUpgradePlan: false, isCheckout: false });
                        sessionStorage.removeItem("contentPlanIds");
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchUserVariation = function (featureIdentifier) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, token, userId, variables, contentApiHeaders, endPoint;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetchUserDetails()];
                case 1:
                    _a = _b.sent(), token = _a.token, userId = _a.userId;
                    variables = {
                        userId: userId,
                        featureIdentifier: featureIdentifier,
                    };
                    contentApiHeaders = {
                        'x-api-key': window === null || window === void 0 ? void 0 : window.xApiKey,
                        authorization: token
                    };
                    endPoint = "".concat(window === null || window === void 0 ? void 0 : window.apiBaseUrl, "/graphql");
                    return [2 /*return*/, request(endPoint, abQuery, variables, contentApiHeaders)];
            }
        });
    }); };
    var handleABConfig = function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var pageId, isABTestingAvailable, abConfigPageData, result, variationID_1, variation, variationId, hashId;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!path) return [3 /*break*/, 3];
                    isABTestingAvailable = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.abConfigs) === null || _c === void 0 ? void 0 : _c.some(function (obj) { return obj.path.includes(path); });
                    if (!isABTestingAvailable) return [3 /*break*/, 2];
                    abConfigPageData = (_e = (_d = window === null || window === void 0 ? void 0 : window.app_data) === null || _d === void 0 ? void 0 : _d.appcmsMain) === null || _e === void 0 ? void 0 : _e.abConfigs.find(function (obj) { return obj.path.includes(path); });
                    return [4 /*yield*/, fetchUserVariation(abConfigPageData === null || abConfigPageData === void 0 ? void 0 : abConfigPageData.featureIdentifier)];
                case 1:
                    result = _g.sent();
                    variationID_1 = (_f = result === null || result === void 0 ? void 0 : result.experimentVariation) === null || _f === void 0 ? void 0 : _f.variationId;
                    variation = abConfigPageData === null || abConfigPageData === void 0 ? void 0 : abConfigPageData.variations.find(function (v) {
                        var matchVariationId = v.variationId === variationID_1 ? true : false;
                        return matchVariationId;
                    });
                    pageId = variation === null || variation === void 0 ? void 0 : variation.pageId;
                    variationId = variation === null || variation === void 0 ? void 0 : variation.variationId;
                    hashId = variation.hash;
                    if (pageId) {
                        logger("".concat(path, ", \"Page available for ABTesting\""));
                        return [2 /*return*/, { isAvailable: true, pageId: pageId, variationId: variationId, hashId: hashId }];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    logger("".concat(path, ", \"Page not available for ABTesting\""), "warn");
                    return [2 /*return*/, { isAvailable: false }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var redirectToPath = function (data) {
        navigate(data);
    };
    useEffect(function () {
        pubsub.subscribe('redirect', redirectToPath);
        // trackEvent('page_view', {page_title: (pageMetaData && pageMetaData.page && pageMetaData.page.metadataMap && pageMetaData.page.metadataMap.title) || "N/A"}, null) 
        sessionStorage.setItem("transactionStarted", JSON.stringify(false));
        if ((location === null || location === void 0 ? void 0 : location.pathname) !== (pageData === null || pageData === void 0 ? void 0 : pageData.path)) {
            handleABConfig(location === null || location === void 0 ? void 0 : location.pathname).then(function (res) {
                var pageId = (res === null || res === void 0 ? void 0 : res.isAvailable) ? res === null || res === void 0 ? void 0 : res.pageId : false;
                var variationId = res === null || res === void 0 ? void 0 : res.variationId;
                var hashId = res === null || res === void 0 ? void 0 : res.hashId;
                var abConfigData = { status: pageId ? true : false, pageId: pageId, variationId: variationId, hashId: hashId };
                pageClientQueryData(pageData, setPageData, abConfigData);
            });
        }
        else {
            setPageData(pageData);
            window.page_data = pageData;
            pubsub.publish('apploading', false);
            fetchFeaturedTrayData(pageData);
        }
        loadSiteStyles();
        if ((pageData === null || pageData === void 0 ? void 0 : pageData.path) != '/tos' && (pageData === null || pageData === void 0 ? void 0 : pageData.path) != '/privacy-policy' && (pageData === null || pageData === void 0 ? void 0 : pageData.path) != '/offers' && (pageData === null || pageData === void 0 ? void 0 : pageData.path) != '/agreement')
            checkVppaCompliance();
        checkForIsRedirected();
        syncSSEStore();
        var bodyTag = document.getElementsByTagName("body");
        if (bodyTag.length > 0) {
            bodyTag[0].style.overflowY = "unset";
            bodyTag[0].style.position = "unset";
        }
        if ((location === null || location === void 0 ? void 0 : location.pathname) === '/') {
            sessionStorage.removeItem("contentPlanIds");
            sessionStorage.removeItem("splashLoginEmail");
            sessionStorage.removeItem("splashSignupEmail");
        }
    }, [location]);
    var handleUserCreated = function (data) {
        var _a;
        if (data === null || data === void 0 ? void 0 : data.status) {
            initializeConnection({
                site: (_a = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.site) === null || _a === void 0 ? void 0 : _a.siteInternalName,
                sseBaseUrl: sseBaseUrl
            });
            unsubscribeUserCreated(function (data) { return handleUserCreated(data); });
        }
    };
    var initiateInterval = function () {
        var _a;
        var storageName = "".concat((_a = siteConfig.site) === null || _a === void 0 ? void 0 : _a.siteInternalName, "-lastTimestamp");
        IntervalInstance = setInterval(function () {
            var _a, _b;
            var lastTimestamp = localStorage.getItem(storageName);
            var broadcastHeartbeat = sessionStorage.getItem('broadcast-heartbeat');
            var currentTime = new Date().getTime();
            var channelName = "".concat((_a = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.site) === null || _a === void 0 ? void 0 : _a.siteInternalName, "-channel");
            var bc = new BroadcastChannel(channelName);
            // unsubscribeUserCreated((data) => handleUserCreated(data,bc))
            // subscribeUserCreated((data) => handleUserCreated(data,bc))
            //checking for tab  receiving events  or not 
            if ((!broadcastHeartbeat || (currentTime - parseInt(broadcastHeartbeat)) > 20000) && lastTimestamp && (currentTime - parseInt(lastTimestamp)) < 20000) {
                listenToChannel(bc);
            }
            if (!lastTimestamp || (currentTime - parseInt(lastTimestamp)) > 20000) {
                initializeConnection({
                    site: (_b = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.site) === null || _b === void 0 ? void 0 : _b.siteInternalName,
                    sseBaseUrl: sseBaseUrl
                });
            }
        }, 20000);
    };
    useEffect(function () {
        var _a, _b, _c;
        handleQueryUrls();
        // console.log("inside useEffect")
        window.addEventListener('load', function () {
            navigator.serviceWorker.register("../firebase-messaging-sw.js")
                .then(function () {
                console.log("SW file registered");
            })
                .catch(function (err) {
                console.log("Error Registering Service Worker" + err);
            });
        });
        //checking to see  last timestamp
        var storageName = "".concat((_a = siteConfig.site) === null || _a === void 0 ? void 0 : _a.siteInternalName, "-lastTimestamp");
        var lastTimestamp = localStorage.getItem(storageName);
        var currentTime = new Date().getTime();
        var channelName = "".concat((_b = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.site) === null || _b === void 0 ? void 0 : _b.siteInternalName, "-channel");
        var bc = new BroadcastChannel(channelName);
        //if there is no  lasttimestamp  || timestamp > 20 sec || no channel for sse
        if (!lastTimestamp || (currentTime - parseInt(lastTimestamp)) > 20000) {
            initializeConnection({
                site: (_c = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.site) === null || _c === void 0 ? void 0 : _c.siteInternalName,
                sseBaseUrl: sseBaseUrl
            });
            initiateInterval();
        }
        else {
            bc.postMessage("ping");
            listenToChannel(bc);
            //checking if connection is still active or not
            initiateInterval();
            console.log("joined a existing  channel");
        }
        handleABConfig(location === null || location === void 0 ? void 0 : location.pathname).then(function (res) {
            var pageId = (res === null || res === void 0 ? void 0 : res.isAvailable) ? res === null || res === void 0 ? void 0 : res.pageId : false;
            var variationId = res === null || res === void 0 ? void 0 : res.variationId;
            var hashId = res === null || res === void 0 ? void 0 : res.hashId;
            var abConfigPayload = { status: pageId ? true : false, pageId: pageId, variationId: variationId, hashId: hashId };
            handlePageViewEvent(pageData, abConfigPayload);
        });
        listenDisposeCookies();
        listenDeviceRemoved();
        // handleAppLogin()
        if (window && typeof (window) !== undefined) {
            localforage.getItem('AuthenticationStore')
                .then(function (store) {
                var _a, _b, _c;
                var token = Cookies.get('token') && JSON.parse(Cookies.get('token'));
                if (((_a = store === null || store === void 0 ? void 0 : store.user) === null || _a === void 0 ? void 0 : _a.userId) && !(token === null || token === void 0 ? void 0 : token.refreshToken)) {
                    fireLogout();
                }
                handleUserLogout((_b = store === null || store === void 0 ? void 0 : store.user) === null || _b === void 0 ? void 0 : _b.userId);
                // handleUserLogout(store?.user?.userId)
                if ((_c = store === null || store === void 0 ? void 0 : store.user) === null || _c === void 0 ? void 0 : _c.userId) {
                    import('./helpers/loadTve').then(function (module) {
                        // console.log(module)
                        var loadtve = module.default;
                        loadtve();
                    });
                    handleUserTransactions();
                }
            });
        }
        subscribeUserCreated(function (data) { return handleUserCreated(data); });
        return (function () {
            clearInterval(IntervalInstance);
        });
    }, []);
    useEffect(function () {
        var queryString = document.location.search.slice(1);
        if (queryString.includes('utm_')) {
            import('./utm').then(function (utmFunc) {
                var utm = utmFunc.utm;
                utm();
            });
        }
    }, []);
    useEffect(function () {
        if (typeof window === "undefined")
            return;
        verifyMagicLink();
    }, []);
    useEffect(function () {
        var _a, _b, _c, _d;
        // Send data to the service worker
        if ('serviceWorker' in navigator) {
            (_a = navigator.serviceWorker.controller) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'app_data', payload: (_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.app_data) === null || _b === void 0 ? void 0 : _b.appcmsMain) === null || _c === void 0 ? void 0 : _c.analytics) === null || _d === void 0 ? void 0 : _d.firebaseConfig });
        }
        if (typeof window !== "undefined") {
            import('./fetchBulkEntitlement').then(function (_a) {
                var getBulkEntitlementData = _a.getBulkEntitlementData;
                getBulkEntitlementData();
            });
        }
    }, []);
    useEffect(function () {
        if (typeof window === "undefined")
            return;
        verifyMagicLink();
    }, []);
    useEffect(function () {
        function loadSplash() {
            var _a, _b, _c, _d;
            var splashConfig = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.splashConfig;
            if (!splashConfig)
                return;
            var isObject = (typeof splashConfig === 'object' && !Array.isArray(splashConfig) && splashConfig !== null) ? true : false;
            if (!isObject)
                return;
            if (((_c = Object.keys(splashConfig)) === null || _c === void 0 ? void 0 : _c.length) <= 0)
                return;
            var activeSplash = (_d = Object.keys(splashConfig)) === null || _d === void 0 ? void 0 : _d.find(function (splash) { var _a; return (((_a = splashConfig[splash]) === null || _a === void 0 ? void 0 : _a.isActive) ? splash : null); });
            if (!activeSplash)
                return false;
            return setActiveSplash(activeSplash);
        }
        loadSplash();
        return function () { };
    }, []);
    if (pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.error) {
        return (React.createElement(Html, { xApiKey: xApiKey, cachedAPI: cachedAPI, sseBaseUrl: sseBaseUrl, siteConfig: siteConfig, pageData: pageMetaData, browserDetails: browserDetails, title: (_40 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _40 === void 0 ? void 0 : _40.site, apiBaseUrl: apiBaseUrl, analyticsBaseUrl: analyticsBaseUrl, wssBaseUrl: wssBaseUrl, env: env, ip: ip, embedCodeToken: embedCodeToken, toolsUrl: toolsUrl, templateUrl: templateUrl },
            React.createElement(Suspense, null,
                React.createElement("div", { className: "main" },
                    React.createElement(NotFound404, null)))));
    }
    else {
        return (React.createElement(Html, { xApiKey: xApiKey, cachedAPI: cachedAPI, sseBaseUrl: sseBaseUrl, siteConfig: siteConfig, pageData: pageMetaData, browserDetails: browserDetails, title: (_41 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _41 === void 0 ? void 0 : _41.site, apiBaseUrl: apiBaseUrl, analyticsBaseUrl: analyticsBaseUrl, wssBaseUrl: wssBaseUrl, env: env, ip: ip, embedCodeToken: embedCodeToken, toolsUrl: toolsUrl, templateUrl: templateUrl },
            React.createElement(Suspense, null,
                activeSplash ? React.createElement(SplashLoader, { name: activeSplash }) : false,
                React.createElement(PersonalizationBanner, null),
                React.createElement(CmsMenu, null),
                React.createElement("div", { className: "main" },
                    React.createElement(LazyLoadModules, { offset: 4, pageData: pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.page, pathName: pageMetaData === null || pageMetaData === void 0 ? void 0 : pageMetaData.path, siteConfig: siteConfig })),
                VLCookieConsent && !disableVlCookieConsent && React.createElement(VLCookieConsent, { genericMessage: configMessages }))));
    }
};
